:root {
    /* Fonts and typography */
    --main-font: "Graphik", sans-serif;

    --h1-h2-font-size: 6.4rem;
    --h3-font-size: 4.8rem;
    --authorization-title: 3.6rem;
    --biggest-font-size: 2.4rem;
    --bigger-font-size: 2rem;
    --regular-font-size: 1.6rem;
    --smaller-font-size: 1.4rem;
    --smallest-font-size: 1.3rem;

    /* Font weight */
    --font-regular: 400;
    --font-bold: 700;

    /* Colors */

    /* Main */
    --theme-color: #c68e24;

    /* Error */
    --error-color: #ec1d1d;

    /* Backgrounds */
    --bg-color: #171717;
    --alternate-bg: #242424;
    --input-bg: #333333;

    /* Regular colors */
    --black: #121212;
    --white: #ffffff;
    --grey: #787878;
    --grey-sub: #999999;
    --red: #ff4242;

    /* Z-indexes */
}

@media screen and (max-width: 991px) {
    :root {
        --h1-h2-font-size: 3.6rem;
        --h3-font-size: 3.2rem;
        --authorization-title: 2.4rem;
        --biggest-font-size: 2rem;
        --bigger-font-size: 1.8rem;
        --regular-font-size: 1.6rem;
        --smaller-font-size: 1.4rem;
        --smallest-font-size: 1.3rem;
    }
}
