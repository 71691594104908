.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    display: flex;
    transform: translate(-50%, -50%);

    span {
        width: 10px;
        height: 80px;
        margin: 20px;
        background: #151618;
        border-radius: 5px;
        box-shadow: 0 0 0 rgba(255, 255, 255, 1);
        animation: animate 1.4s linear infinite;

        &:nth-child(1) {
            animation-delay: 0s;
        }

        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0.4s;
        }

        &:nth-child(4) {
            animation-delay: 0.6s;
        }

        &:nth-child(5) {
            animation-delay: 0.8s;
        }

        &:nth-child(6) {
            animation-delay: 1s;
        }

        &:nth-child(7) {
            animation-delay: 1.2s;
        }
    }

    &--small {
        position: static;
        top: auto;
        left: auto;
        transform: translate(0);

        span {
            height: 40px;
            margin: 10px;
        }
    }
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: translateX(-50px) scale(1);
    }

    50% {
        box-shadow: 0 0 5px var(--theme-color);
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
