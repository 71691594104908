/* Reusable flex class for components */
.flex {
    display: flex;

    &--column {
        flex-direction: column;
    }
}

.align-left {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-right {
    align-items: flex-end;
}

.align-str {
    align-items: stretch;
}

.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-space {
    justify-content: space-between;
}

.self-start {
    align-self: flex-start;
}

.self-center {
    align-self: center;
}

.self-end {
    align-self: flex-end;
}

/* Reusable grid class for components */
.grid {
    display: grid;
}

.order-first {
    order: -1;
}

.text-start {
    text-align: start !important;
}

.text-center {
    text-align: center !important;
}

.text-end {
    text-align: end !important;
}
