/* Base styles */
*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body,
#root,
.page-wrapper {
    min-height: 100vh;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    font-size: 62.5%;
}

body {
    overflow-x: hidden;
    font-family: var(--main-font);
    font-size: var(--regular-font-size);
    font-weight: var(--font-regular);
    line-height: 1.5;
    color: var(--white);
    background-color: var(--black);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

ul {
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

button,
input {
    cursor: pointer;
    background-color: transparent;
    border: none;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    outline: none;
}

img,
video {
    width: 100%;
    height: auto;
    object-fit: cover;
}

::-webkit-scrollbar {
    width: 8px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background: var(--theme-color);
    border-radius: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 1rem;
}
